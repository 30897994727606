body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button:active {
  opacity: 0.5;
}

button:focus {
  outline: 0;
}

img:active {
  opacity: 0.5;
}
.viewRoot {
  text-align: center;
  flex-direction: column;
}

.viewLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  font-weight: bold;
  font-size: 20px;
  color: #203152;
  background-color: #f5a623;
  padding: 20px;
  box-shadow: 0px 4px 4px #808888;
}

/* Button login */

.btnLogin {
  background-color: black;
  border: none;
  color: white;
  padding: 10px;
  width: 400px;
  text-align: center;
  font-size: 20px;
  align-self: center;
  border-radius: 8px;
  font-weight: bold;
  margin-top: 200px;
  outline: 0;
  cursor: pointer;
}
/* Welcome board */
.viewWelcomeBoard {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textTitleWelcome {
  font-size: 22px;
  font-weight: bold;
  color: #203152;
}

.textDesciptionWelcome {
  font-size: 20px;
  color: #203152;
  margin-bottom: 200px;
}

.avatarWelcome {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: cover;
}

.root {
  text-align: center;
  flex-direction: column;
  display: flex;
}

.header {
  font-weight: bold;
  font-size: 20px;
  color: #203152;
  background-color: #f5a623;
  padding: 20px;
  box-shadow: 0px 4px 4px #808888;
  flex-direction: row;
}

.body {
  display: flex;
  flex-direction: 'row';
  padding-top: 5px;
}

.viewLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icLogout {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0;
  margin-right: 30px;
  cursor: pointer;
}

.icProfile {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0;
  margin-right: 80px;
  cursor: pointer;
}

/* Dialog confirm */

.viewCoverScreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewWrapTextDialogConfirmLogout {
  width: 300px;
  height: 50px;
  background-color: #f5a623;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center
}

.titleDialogConfirmLogout {
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.viewWrapButtonDialogConfirmLogout {
  flex-direction: row;
  height: 100px;
  width: 300px;
  min-width: 300px;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.btnYes {
  width: 100px;
  height: 40px;
  font-weight: bold;
  margin-right: 20px;
  background-color: #203152;
  border: 0px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.btnNo {
  width: 100px;
  height: 40px;
  font-weight: bold;
  margin-left: 20px;
  padding: 5px;
  background-color: #dd4b39;
  border: 0px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

/* List user */

.viewListUser {
  overflow-y: scroll;
  max-height: 90vh;
  padding-top: 10px;
  padding-bottom: 10px;
}

.viewListUser::-webkit-scrollbar-track {
  padding: 2px 0;
  background-color: #e8e8e8;
}

.viewListUser::-webkit-scrollbar {
  width: 6px;
}

.viewListUser::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #203152;
}

.viewWrapItem {
  border: none;
  display: flex;
  flex-direction: 'row';
  background-color: #ffffff;
  width: 330px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.viewWrapItemFocused {
  border: none;
  display: flex;
  flex-direction: 'row';
  background-color: #e8e8e8;
  width: 330px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.viewAvatarItem {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
}

.viewWrapContentItem {
  flex-direction: column;
  display: flex;
  flex: 1 1;
  margin-left: 15px;
  color: #203152;
  word-wrap: break-word;
}

.textItem {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 240px;
  text-align: left;
  font-size: 14px;
}

.viewBoard {
  display: flex;
  flex: 1 1;
  max-height: 90vh;
}
.viewRoot {
  flex-direction: column;
}

.viewLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  font-weight: bold;
  font-size: 20px;
  color: #203152;
  background-color: #f5a623;
  padding: 20px;
  box-shadow: 0px 4px 4px #808888;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  align-self: center;
  margin-top: 50px;
  object-fit: cover;
}

.textLabel {
  font-weight: bold;
  color: #203152;
  font-style: italic;
  font-size: 18px;
  margin-top: 50px;
  margin-left: -400px;
}

.textInput {
  width: 500px;
  align-self: center;
  border: none;
  background-color: #203152;
  border-radius: 3px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  margin-top: 10px;
}

input::-webkit-input-placeholder {
  color: rgb(199, 199, 199);
}

input::-ms-input-placeholder {
  color: rgb(199, 199, 199);
}

input::placeholder {
  color: rgb(199, 199, 199);
}

.btnUpdate {
  background-color: #f5a623;
  border: none;
  color: #203152;
  padding: 10px;
  width: 200px;
  text-align: center;
  font-size: 20px;
  align-self: center;
  border-radius: 8px;
  font-weight: bold;
  margin-top: 100px;
  cursor: pointer;
}

.viewWrapInputFile {
  position: absolute;
  align-self: center;
  top: 215px;
  background-color: rgba(1, 1, 1, 0.5);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center
}

.viewInputFile {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0px;
  outline: 0;
  z-index: -1;
  opacity: 0;
}

.imgInputFile {
  border: none;
  width: 40px;
  height: 40px;
  padding: 5px;
}
